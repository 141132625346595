import React, { useCallback, useEffect, useState } from 'react'

import { useTelegram } from '../../hooks/useTelegram'
import './TUSForm.css'

interface ITusConfiguration {
    id: number,
    name: string
}

const tusConfigurations: ITusConfiguration[] = [
    { id: 0, name: 'Выберите конфигурацию вашей 1С' },
    { id: 1, name: '1С: Далион Тренд' },
    { id: 2, name: '1С: Далион ПРО' },
    { id: 3, name: '1С: Розница' },
    { id: 4, name: '1С: Управление торговлей 10' },
    { id: 5, name: '1С: Управление торговлей 11' },
    { id: 6, name: '1С: Штрих-М' }
]

interface ITus {
    tusConfiguration: ITusConfiguration,
    tusStore: string,
    tusPriceRetail: string,
    tusPricePurchase: string,
    dataType: string,
    queryId: string | undefined,
    chatId: number | undefined
}

const defaultTus: ITus = {
    tusConfiguration: tusConfigurations[0],
    tusStore: '',
    tusPriceRetail: '',
    tusPricePurchase: '',
    dataType: 'tus',
    queryId: undefined,
    chatId: undefined
}

const TusForm = () => {
    const { tg, queryId, user, chat } = useTelegram()
    const [tus, setTus] = useState<ITus>(defaultTus)

    const checkData = (): boolean => {
        if (!tus.tusConfiguration.id) return false
        if (tus.tusStore.trim().length < 1) return false
        if (tus.tusPriceRetail.trim().length < 1) return false
        if (tus.tusPricePurchase.trim().length < 1) return false
        return true
    }

    const onSendData = useCallback(() => {
        tg.sendData(JSON.stringify(tus))
    }, [tus])

    useEffect(() => {
        tg.MainButton.setParams({
            text: 'Отправить данные'
        })
    }, [])

    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData)
        return () => {
            tg.offEvent('mainButtonClicked', onSendData)
        }
    }, [onSendData])

    useEffect(() => {
        if(!checkData()) {
            tg.MainButton.hide();
        } else {
            tg.MainButton.show();
        }
    }, [tus])

    function onChangeConfiguration(e: { target: { value: any } }) {
        setTus({ ...tus, tusConfiguration: tusConfigurations.filter(c => c.id == e.target.value)[0] })
    }

    function onChangeStore(e: { target: { value: any } }) {
        setTus({ ...tus, tusStore: e.target.value })
    }

    function onChangePriceRetail(e: { target: { value: any } }) {
        setTus({ ...tus, tusPriceRetail: e.target.value })
    }

    function onChangePricePurchase(e: { target: { value: any } }) {
        setTus({ ...tus, tusPricePurchase: e.target.value })
    }

    return (
        <div className={"form"}>
            <h3>Введите ваши данные</h3>
            <select value={tus.tusConfiguration.id} onChange={onChangeConfiguration} className={'select'}>
                {tusConfigurations.map(conf => (
                    <option key={conf.id} value={conf.id}>{conf.name}</option>
                ))}
            </select>
            <input
                className={'input'}
                type="text"
                placeholder={'Код склада'}
                value={tus.tusStore}
                onChange={onChangeStore}
            />
            <input
                className={'input'}
                type="text"
                placeholder={'Код розничной цены'}
                value={tus.tusPriceRetail}
                onChange={onChangePriceRetail}
            />
            <input
                className={'input'}
                type="text"
                placeholder={'Код закупочной цены'}
                value={tus.tusPricePurchase}
                onChange={onChangePricePurchase}
            />
            <hr/>
            <p>ВНИМАНИЕ! Необходимо указать значения в полном соответствии с данными из 1С (лидирующие нули и т.д.)</p>
            <p>Если у вас в 1С не отображаются коды, то введите наименования.</p>
        </div>
    )
}

export default TusForm