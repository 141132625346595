import React from 'react'
import { Routes, Route } from 'react-router-dom'

import ModelForm from './components/ModelForm/ModelForm'
import TUSForm from './components/TUSForm/TUSForm'
import Header from './components/Header/Header'

function App() {
    return (
        <div className="App">
            <Header />
            <Routes>
                <Route index path={'/ModelForm'} element={<ModelForm />} />
                <Route path={'/TUSForm'} element={<TUSForm />} />
            </Routes>
        </div>
    )
}

export default App