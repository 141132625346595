import React, { useCallback, useEffect, useState } from 'react'

import { useTelegram } from '../../hooks/useTelegram'
import './ModelForm.css'

interface IRegion {
    id: string,
    name: string
}

interface IShop {
    shopName: string,
    shopRegion: IRegion,
    shopAddress: string,
    shopEmail: string,
    dataType: string,
    queryId: string | undefined,
    chatId: number | undefined
}

const defaultShop: IShop = {
    shopName: '',
    shopRegion: { id: '', name: '' },
    shopAddress: '',
    shopEmail: '',
    dataType: 'shop',
    queryId: undefined,
    chatId: undefined
}

const ModelForm = () => {
    const [shop, setShop] = useState<IShop>(defaultShop)
    const { tg, queryId, user, chat } = useTelegram()

    const regions: any = require('../../data/russia-regions.json')

    const checkData = (): boolean => {
        if (shop.shopName.trim().length < 3) return false
        if (shop.shopAddress.trim().length < 5) return false
        if (!shop.shopRegion.id) return false
        if (!shop.shopEmail.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)) return false
        return true
    }

    const onSendData = useCallback(() => {
        tg.sendData(JSON.stringify(shop))
    }, [shop])

    useEffect(() => {
        setShop({ ...shop, shopRegion: { id: regions[0]['iso_3166-2'], name: regions[0].fullname } })
        tg.MainButton.setParams({
            text: 'Отправить данные'
        })
    }, [])

    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData)
        return () => {
            tg.offEvent('mainButtonClicked', onSendData)
        }
    }, [onSendData])

    useEffect(() => {
        if (!checkData()) {
            tg.MainButton.hide();
        } else {
            tg.MainButton.show();
        }
    }, [shop])

    function onChangeShopName(e: { target: { value: any } }) {
        setShop({ ...shop, shopName: e.target.value })
    }

    function onChangeShopRegion(e: { target: { value: any } }) {
        const region = regions.filter((r: { [x: string]: any }) => r['iso_3166-2'] === e.target.value)[0]
        console.log(region)
        setShop({ ...shop, shopRegion: { id: region['iso_3166-2'], name: region.fullname } })
    }

    function onChangeShopAddress(e: { target: { value: any } }) {
        setShop({ ...shop, shopAddress: e.target.value })
    }

    function onChangeShopEmail(e: { target: { value: any } }) {
        setShop({ ...shop, shopEmail: e.target.value })
    }

    return (
        <div className={"form"}>
            <h3>Введите ваши данные</h3>
            <input
                className={'input'}
                type="text"
                placeholder={'Название магазина'}
                value={shop.shopName}
                onChange={onChangeShopName}
            />
            <select value={shop.shopRegion.id} onChange={onChangeShopRegion} className={'select'}>
                {regions.map((r: { 'iso_3166-2': string, fullname: string }) => (
                    <option key={r['iso_3166-2']} value={r['iso_3166-2']}>{r.fullname}</option>
                ))}
            </select>
            {/*<input*/}
            {/*    className={'input'}*/}
            {/*    type="text"*/}
            {/*    placeholder={'Регион, в котором находится магазин'}*/}
            {/*    value={shop.shopRegion}*/}
            {/*    onChange={onChangeShopRegion}*/}
            {/*/>*/}
            <input
                className={'input'}
                type="text"
                placeholder={'Адрес магазина'}
                value={shop.shopAddress}
                onChange={onChangeShopAddress}
            />
            <input
                className={'input'}
                type="text"
                placeholder={'Email ответственного лица'}
                value={shop.shopEmail}
                onChange={onChangeShopEmail}
            />
        </div>
    );
};

export default ModelForm;